import { getParentBySelector } from '../helper'

// Filters a section with multiple elements like brands to show only the ones
// that match the content we type into the according search input
export class SearchList {
  constructor(selector, isIndex = false) {
    let elements = document.querySelectorAll(selector)
    this.isIndex = isIndex

    for (let el of elements) {
      new SearchListElement(el, this.isIndex)
    }
  }
}

export class SearchListElement {
  constructor(el, isIndex = false) {
    this.element = el
    this.listClassName = this.element.dataset.list
    this.noResultsListClassName = `${this.listClassName}--no-results`
    this.isIndex = isIndex

    this.list = document.querySelector(this.listClassName)

    if (this.list) {
      this.items = this.list.querySelectorAll('.homepage__brands-item a')

      this.itemsHeader = this.list.querySelectorAll('.brands-landing__header')

      if (this.items.length) {
        this.noResultsList = document.querySelector(this.noResultsListClassName)

        this.element.addEventListener('keyup', function () {
          this.filter(this.element.value.trim().toLowerCase())

          window.homepageBrandsSwiper.updateSlides()
          window.homepageBrandsSwiper.slideTo(0)
        }.bind(this))
      }
    }
  }

  filter(word) {
    let length = this.items.length
    let hidden = 0

    if (word.length == 0) {
      for (let i = 0; i < length; i++) {
        getParentBySelector(this.items[i], '.homepage__brands-item').classList.remove('d-none')
      }
    } else {
      for (let i = 0; i < length; i++) {
        if (this.items[i].innerText.trim().toLowerCase().includes(word)) {
          getParentBySelector(this.items[i], '.homepage__brands-item').classList.remove('d-none')
        }
        else {
          getParentBySelector(this.items[i], '.homepage__brands-item').classList.add('d-none')
          hidden++
        }
      }
    }

    if (this.isIndex) {
      this.itemsHeader.forEach(itemHeader => {
        let childCount = itemHeader.querySelectorAll('.homepage__brands-item').length
        let hiddenChildCount = itemHeader.querySelectorAll('.homepage__brands-item.d-none').length

        if (childCount == hiddenChildCount) {
          itemHeader.classList.add('d-none')
        }
        else {
          itemHeader.classList.remove('d-none')
        }
      })
    }

    // If all items are hidden, show the empty view
    if (hidden === length && this.noResultsList ) {
      this.noResultsList.classList.remove('d-none')
      console.log(this.isIndex)
    } else {
      this.noResultsList.classList.add('d-none')
    }
  }
}
