export function bindOrderEvents() {
  const billing = $('.billing-address')
  const shipping = $('.shipping-address')
  const shippingForm = document.querySelector(".shipping-form")

  const disableShippingFields = () => {
     const shippingCheckbox = document.getElementById("use_as_shipping_info")

    if (shippingCheckbox) {
      if (shippingCheckbox.checked) {
        shippingForm.style.display = "none"
      }
      else {
        shippingForm.style.display = "block"
      }
    }
  }

  const toggleInvoiceFields = () => {
    if ($("input[name='order[is_invoice]']:checked").val() == "true") {
      $(".invoice-fields").slideDown('slow')
    } else {
      $(".invoice-fields").slideUp('fast')
    }
  }

  billing.find('input[type=text], input[type=tel], input[type=email], select').bind('keyup change', function(e) {
    if ($('#use_as_shipping_info').is(':checked')) {
      let target = $('#' + $(this).attr('id').replace('billing', 'shipping'))
      target.val($(this).val())
    }
  })

  $.each([billing, shipping], function() {
    let container = $(this)
    let address_select = container.find('select[id$=_address_id]')
    let inputs = container.find('input[type="text"]')
    let selects = container.find('select').not(address_select)

    let reset_address_select = () => {
      if (!address_select.find('option:first').is(':selected')) {
        address_select.find('option:selected').removeAttr('selected')
        address_select.find('option:first').attr('selected', 'selected')
      }
    }

    $(selects).change(reset_address_select)
    $(inputs).keydown(reset_address_select)

    address_select.change(function() {
      inputs.val('')
      selects.find('option:selected').removeAttr('selected')
      selects.find('option:first').attr('selected', 'selected')

      data = $(this).find('option:selected').data('address')

      if (data) {
        for (const [key, value] of Object.entries(data)) {
          inputs.filter("[id$=_attributes_#{key}]").val(value)
          selects.filter("[id$=_attributes_#{key}]").find("option[value=#{value}]").attr('selected', 'selected')
        }
      }
    })
  })

  $("input[name='order[is_invoice]']").change( () => {
    toggleInvoiceFields()
  })
  toggleInvoiceFields()

  disableShippingFields()

  $('#use_as_shipping_info').click( () => {
    disableShippingFields()
  })

  // Gift section toggler
  if (document.querySelector(".checkout-2")) {
    const giftTextarea = document.querySelector(".gift-textarea")
    const giftCheckbox = document.querySelector("#order_is_gift")

    const toggleGiftTextBox = () => {
      if (giftCheckbox.checked) {
        giftTextarea.removeAttribute("disabled")
      } else {
        giftTextarea.setAttribute("disabled", true)
      }
    }

    if (giftCheckbox) {
      giftCheckbox.addEventListener("click", function() {
        toggleGiftTextBox()
      })

      toggleGiftTextBox()
    }
  }
}