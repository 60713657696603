export class NotificationBar {
  constructor(type, text, callback = (() => { }), timeout = 4000, selector = ".alert-wrapper") {
    requestIdleCallback(() => {
      this.type = type
      this.timeout = timeout
      this.text = text

      this.notifications = []
      this.notificationsWrapper = document.querySelector(selector)

      this._show()

      callback()
    })
  }

  _getType() {
    switch (this.type) {
      case "success" || "info": return "alert-confirm"
      case "error" || "warning": return "alert-error"
    }
  }

  _create(position) {
    const notification = document.createElement("div")

    notification.classList.add("alert", "alert__item", "border-0", "rounded-0", "mx-auto", "p-3")
    notification.innerHTML = this.text

    this.notifications.push(notification)
    this.notificationsWrapper.prepend(this.notifications[position])
  }

  _remove() {
    for (let notification of this.notifications) {
      notification.addEventListener("click", function () {
        notification.style.animationDuration = "1.1s"
      })
    }
  }

  _show() {
    let notificationPosition = this.notifications.length

    this._create(notificationPosition)
    this._remove()

    this.notifications[notificationPosition].classList.add(`${this._getType()}`)

    setTimeout(() => {
      this.notifications[notificationPosition].remove()
    }, this.timeout)
  }
}
