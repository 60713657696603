import { NotificationBar } from "./components/NotificationBar"
import Swiper from "swiper"
import tippy from "tippy.js"

export function bindGYEvents() {
  window.initializeBoxNowWidget = function() {
    const iframe = document.querySelector('#boxnowmap iframe')

    // Do not allow the widget to be loaded more than once
    if (window.initializeBoxNowWidget.alreadyRun && iframe)
      return

    window.initializeBoxNowWidget.alreadyRun = true

    const shippingZip = document.getElementById('order_billing_address_attributes_zip')
    const gpsEnabled = shippingZip.value.length == 0

    window._bn_map_widget_config = {
      autoclose: false,
      autoshow: true,
      gps: gpsEnabled,
      zip: shippingZip.value,
      partnerId: window.gyx_config.box_now.partner_id,
      parentElement: "#boxnowmap",
      afterSelect: function (selected) {
        const boxNowKeys = [
          "boxnowLockerAddressLine1", "boxnowLockerAddressLine2", "boxnowLockerId",
          "boxnowLockerLat", "boxnowLockerLng", "boxnowLockerName", "boxnowLockerPostalCode"
        ]

        boxNowKeys.forEach((key) => {
          const value = selected[key] || ""
          const element = document.getElementById(key)

          if (element)
            element.value = value
        })
      }
    }; // Do not remove this semicolon

    (function (d) {
      var e = d.createElement('script')
      const shippingCountryCode = document.getElementById('order_shipping_address_attributes_country_code')

      let box_now_url

      if (shippingCountryCode.value === 'CY') {
        box_now_url = 'https://widget-cdn.boxnow.cy/map-widget/client/v5.js'
      } else {
        box_now_url = 'https://widget-cdn.boxnow.gr/map-widget/client/v5.js'
      }

      e.src = box_now_url
      e.async = true
      e.defer = true
      d.getElementsByTagName("head")[0].appendChild(e)
    })(document)
  }

  window.gyEvents = {
    /**
     * Triggered when options (color, size etc) for given product start loading.
     * @param {event} the event that was triggered
     */
    optionsStartLoading: function (event) { },

    /**
     * Triggered when options (color, size etc) for given product are loaded.
     * @param {event} the event that was triggered
     */
    optionsLoaded: function (event) {},

    optionVariantChanged: function (event) {},

    /**
     * Triggered when photos of product have been changed.
     * @param {event} the event that was triggered
     */
    photosChanged: function (event) {
      new Swiper('.gallery-top', {
        watchOverflow: true,
        thumbs: {
          swiper: {
            el: '.gallery-thumbs',
            slidesPerView: 3.7,
          },
          navigation: {
            nextEl: '.gallery-thumbs .swiper-button-next',
            prevEl: '.gallery-thumbs .swiper-button-prev',
          },
          breakpoints: {
            576: {
              slidesPerView: 4,
            }
          }
        }
      })

      const product = $('.product-page')
      const zoom = product.find('.zoom-button-mobile')

      zoom.on('click', function(e) {
        const activeImage = product.find('.gallery-top').find('.swiper-slide-active').find('img').attr('data-zoom')
        product.find('.modal-body').find('img').attr("src", activeImage);
      })
    },

    /**
     * Triggered when something is added to cart.
     * @param {event} the event that was triggered
     */
    addedToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )

      $('.quick-buy').removeClass('active');
    },

    /**
     * Triggered when a 401 is returned from specific actions. For example when user tries to adds a
     * product to wishlist and he isn't already logged in.
     * Used to display login popup form.
     * @param {event} the event that was triggered
     */
    userNeedsLogin: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    /**
     * Triggered when wishlist is loaded.
     * @param {event} the event that was triggered
     */
    wishlistLoaded: function (event) {},

    bundlesLoaded: function (event) {
      window.initHeightEqualizer()
    },

    /**
     * Triggered when a product is added to wishlist. Usefull to show wishlist to user.
     * @param {event} the event that was triggered
     */
    addedToWishlist: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    lineItemDeleted: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    /**
     * Triggered when a product already exists in wishlist. Usefull to show wishlist to user, and message.
     * @param {event} the event that was triggered
     */
    alreadyExistsInWishlist: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    /**
     * Triggered when a wishlist item is deleted.
     * @param {event} the event that was triggered
     */
    wishlistItemDeleted: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    /**
     * Triggered when a wishlist item is added to cart.
     * @param {event} the event that was triggered
     */
    wishlistAddedToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    /**
     * Triggered when ALL wishlist items are added to cart.
     * @param {event} the event that was triggered
     */
    wishlistAddedAllToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    /**
     * Triggered when user successfully logs in (after completing username & password in login form).
     * @param {event} the event that was triggered
     */
    userLoggedIn: function (event) {
      // Google Analytics 4
      if (typeof dataLayer !== 'undefined') {
        // Clear the previous ecommerce object.
        dataLayer.push({ ecommerce: null })

        // Push the Sign In event to Data Layer
        dataLayer.push({ event: 'login' })
      }

      window.location.reload();
    },

    /**
     * Triggered when user enters incorrect login details (wrong username and/or password).
     * @param {event} the event that was triggered
     */
    userNotAuthorized: function (event) {
      new NotificationBar(
        "error",
        event.detail.message
      )
    },

    /**
     * Triggered when user successfully registers.
     * @param {event} the event that was triggered
     */
    userRegistered: function (event) {
      // Google Analytics 4
      if (typeof dataLayer !== 'undefined') {
        // Clear the previous ecommerce object.
        dataLayer.push({ ecommerce: null })

        // Push the Sign Up event to Data Layer
        dataLayer.push({ event: 'sign_up' })
      }

      window.location.reload();
    },

    /**
     * Triggered when user didn't register, due to validation errors (wrong password length etc).
     * @param {event} the event that was triggered
     */
    userNotRegistered: function (event) {},

    /**
     * Triggered when app is mounted.
     * @param {event} the event that was triggered
     */
    appMounted: function (event) {},

    /**
     * Triggered when the visitor clicks on a specific filter through AJAX
     * @param {event} the event that was triggered
     */
    catalogVisitStarted: function (event) {
      $('.loading-mask').addClass('show');
    },

    /**
     * Triggered when the visitor clicks on a specific filter through AJAX
     * @param {event} the event that was triggered
     */
    catalogVisitDone: function (event) {
      window.catalogFilters()
      window.unfreezeBackground()
      window.initFiltersToggle()
    },

    productListLoaded: function (event) {
      $('.loading-mask').removeClass('show')

      requestIdleCallback(() => {
        window.productInit()

        window.initHeightEqualizer()
        window.initHomepageTopProducts()
        window.initHomepageNewProductsSlider()
        window.initHomepagePromotionsSlider()
        window.initBrandLandingSlider()
        window.initBrandDiscoverTabSlider()
        window.initializeRecentProductsSlider()
        window.initializeRelatedProductsSlider()
        window.initMainProductSlider()
      })
    },

    /**
     * Triggered when next page is asked using AJAX.
     * @param {event} the event that was triggered
     */
    catalogPaginate: function (event) {},

    /**
     * Triggered when we have gotten to last page of catalog.
     * @param {event} the event that was triggered
     */
    catalogLastPage: function (event) {},

    couponActivated: function (event) { },

    couponInvalid: function (event) { },

    couponeactivated: function (event) { },

    /**
     * Triggered when the minicart quantity is updated
     * @param {event} the event that was triggered
     */
    lineItemQuantityUpdated: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
     },

    lineItemMaxQuantityReached: function (event) { },

    paymentMethodsLoaded: function (event) { },

    shippingMethodsLoaded: function (event) {
      tippy('[data-tippy-content]', {
        theme: 'light-border',
        placement: 'left-end',
        allowHTML: true,
        maxWidth: 280
      })
     },

    countriesLoaded: function (event) { },

    regionsLoaded: function (event) { },

    vatOfficesLoaded: function (event) { },

    bundleCannotAddToCart: function (event) {
      $(".promotions .product .quick-buy").addClass("active");
    },

    bundleAddedToCart: function (event) {
      new NotificationBar(
        "success",
        event.detail.message
      )
    },

    storeFilterSelected: function (event) { },

    addressFormPopulated: function (event) { },

    addressDeleted: function (event) { },

    addressErrorDeleted: function (event) { },

    addressUpdated: function (event) { },

    addressCreated: function (event) { },

    checkoutAddressPopulated: function (event) { },

    promotionsLoaded: function (event) { },

    promotionAddedToCart: function (event) { },
  }
}
