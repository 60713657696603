/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
import $ from 'jquery'
import {} from 'jquery-ujs'
import hoverIntent from 'hoverintent'
import Swiper from 'swiper'
import 'bootstrap/js/dist/tab'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'
import 'bootstrap/js/dist/collapse'
import Cookies from 'js-cookie'

import LazyLoad from "vanilla-lazyload"
import WebFont from "webfontloader"

import { bindGYEvents } from '../gy-events'
import { bindOrderEvents } from '../order'
import { HeightEqualizer } from '../components/HeightEqualizer'
import { SearchList } from '../classes/SearchList'

import 'stylesheets/application.scss'

window.$ = window.jQuery = $

const GY = {
  settings: {
    homepageSlider: $('.homepage__slider').find('.swiper-container'),
    homepageCategories: $('.homepage__categories').find('.swiper-container'),
    homepageVerticalScroll: $('.homepage__scroll-products--wrapper').find('.swiper-container'),
    homepageArticle: $('.homepage__articles').find('.swiper-container'),
    articleSlider: $('.article-page__slider').find('.swiper-container')
  },

  init: function () {
    this.initWebFonts()

    bindGYEvents()
    bindOrderEvents()

    this.initStickyHeader()
    this.initHomepageTopProducts()
    this.initHomepageNewProductsSlider()
    this.initHomepagePromotions()
    this.initHomepagePromotionsSlider()
    this.initHeightEqualizer()

    this.initVanillaLazyLoad()
    this.initHomepageSlider()
    this.initMainMenu()
    this.initSplitTitle()
    this.homepageVerticalScroll()
    this.initHomepageArticles()
    this.initHomepageCategories()
    this.initBrandsSlider()
    this.initArticlesPageSlider()
    this.productInit()
    this.panelsInit()
    this.productPage()
    this.changeMenuBannersOnHover()
    this.brandLandingSlider()
    this.brandDiscoverSlider()
    this.initBrandDiscoverTabSlider()
    this.initRecentProductsSlider()
    this.initRelatedProductsSlider()
    this.responsiveMenu()
    this.searchInit()
    this.catalogFilters()
    this.initStaticPagesMenu()
    this.stopPropagationSearch()
    this.animate()
    this.faqToggler()
    this.unfreezeBackground()
    this.initFiltersToggle()
    this.stickyButtons()
    this.newsletterPopup()
    this.initMenuTabs()
    this.openSearch()
    this.catalogBannerChecker()
    this.initZopimManager()
    this.initProductThumbNavigation()
    this.initSearchList()
    this.initYoutubeVideos()
    this.initCheckoutFocusManager()
  },

  initWebFonts: function() {
    WebFont.load({
      typekit: {
        id: 'gba8xlg'
      }
    })
  },

  initVanillaLazyLoad: function() {
    setTimeout(() => {
      new LazyLoad({ elements_selector: "[lazyload]" })
    })
  },

  stopPropagationSearch: function () {
    $('html').on('click', function (e) {
      let containerclicked = $('.search-form')
      if (!containerclicked.is(e.target) && containerclicked.has(e.target).length === 0) {
        $('.search-form').removeClass('has-results')
      }
    })
  },

  freezeBackground() {
    $('html, body').css({
      overflow: 'hidden',
      position: 'relative',
      height: '100%'
    })
  },

  unfreezeBackground() {
    window.unfreezeBackground = function() {
      $('html, body').css({
        overflow: 'unset',
        height: 'auto'
      })
    }
    window.unfreezeBackground()
  },

  productInit: function () {
    window.productInit = function () {
      $(document.body).on('click', '.trigger-quickbuy', function (e) {
        e.preventDefault()
        $(this).parents('.product').find('.quick-buy').addClass('active')
        $(this).parents('.product').find('.add-to-cart-section').hide()
        $(this).parents('.faq-item').addClass('active')
      })

      $(document.body).on('click', '.quick-buy__close', function (e) {
        e.preventDefault()
        $(this).parents('.product').find('.quick-buy').removeClass('active')
        $(this).parents('.product-page').find('.quick-buy').removeClass('active')
        $(this).parents('.product').find('.add-to-cart-section').show()
        $(this).parents('.faq-item').addClass('active')
      })

      $(document.body).on('click', '.trigger-mobile-quickbuy', function (e) {
        e.preventDefault()
        $(this).parents('.product-page').find('.quick-buy').addClass('active')
      })
    }
  },

  panelsInit: function () {
    $('a[data-panel="minicart"]').on('click', function (e) {
      e.preventDefault()

      $('.minicart-panel').addClass('minicart-panel--active')
      $('body, html').css('overflow', 'hidden')
      window.zopimHide()
    })

    $('a[data-panel="search"]').on('click', function (e) {
      e.preventDefault()
      $('.search-panel').addClass('active-panel')
      $('body').css('overflow', 'hidden')
    })

    $('.minicart-panel__close').on('click', function (e) {
      e.preventDefault()
      $(this).parent().removeClass('minicart-panel--active')
      $('body, html').css('overflow', 'unset')
      window.zopimShow()
    })
  },

  productPage: function () {
    window.initMainProductSlider = function () {
      const productPage = document.querySelector('.product-page')

      if (productPage) {
        new Swiper(productPage.querySelector('.gallery-thumbs.swiper-container'), {
          spaceBetween: 10,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          navigation: {
            nextEl: '.gallery-thumbs.swiper-container .swiper-button-next',
            prevEl: '.gallery-thumbs.swiper-container .swiper-button-prev'
          }
        })

        new Swiper(productPage.querySelector('.product-main-slider.swiper-container'), {
          slidesPerView: 1,
          slidesPerGroup: 1,
          watchOverflow: true,
          thumbs: {
            productSwiper: galleryThumbs
          },
          autoScrollOffset: 1
        })
      }
    }
  },

  initSplitTitle: function () {
    $('.split-title').each(function() {
      var text = $(this).text().split(' ')
      for ( var i = 0, len = text.length; i < len; i++ ) {
        text[i] = '<span>' + text[i] + '</span>'
      }

      $(this).html(text.join(' '))
    })
  },

  //////// HOMEPAGE
  initHomepageSlider: function () {
    if (this.settings.homepageSlider.length) {
      new Swiper(this.settings.homepageSlider.get(0), {
        slidesPerView: 1,
        preloadImages: false,
        autoplay: {
          delay: 3000
        },
        lazy: true,
        pagination: {
          el: '.homepage__slider .swiper-pagination',
          clickable: true
        }
      })
    }
  },

  initHomepageTopProducts: function () {
    window.initHomepageTopProducts = function() {
      if ($('.homepage__top-products').find('.swiper-container').length) {
        new Swiper($('.homepage__top-products').find('.swiper-container').get(0), {
          slidesPerView: 1.4,
          slidesPerGroup: 1,
          watchOverflow: true,
          spaceBetween: 5,
          pagination: {
            el: '.homepage__top-products .new-products-pagination',
            clickable: true
          },
          breakpoints: {
            576: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            }
          }
        })
      }
    }
  },

  initHomepageCategories: function () {
    if (this.settings.homepageCategories.length) {
      new Swiper(this.settings.homepageCategories.get(0), {
        slidesPerView: 1.5,
        slidesPerGroup: 1,
        watchOverflow: true,
        spaceBetween: 10,
        preloadImages: false,
        lazy: true,
        breakpoints: {
          576: {
            slidesPerView: 2.5
          },
          992: {
            slidesPerView: 3.5
          },
        },
        pagination: {
          el: '.homepage__categories .swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.homepage__categories .swiper-button-next',
          prevEl: '.homepage__categories .swiper-button-prev'
        },
      })
    }
  },

  homepageVerticalScroll: function () {
    if (this.settings.homepageVerticalScroll.length && window.innerWidth <= 991) {
      new Swiper(this.settings.homepageVerticalScroll.get(0), {
        slidesPerView: 1.5,
        spaceBetween: 5,
        lazy: true,
        navigation: {
          nextEl: '.homepage__scroll-products--wrapper .swiper-button-next',
          prevEl: '.homepage__scroll-products--wrapper .swiper-button-prev',
        }
      })
    }
  },

  initSearchList: function () {
    new SearchList(".homepage__brands input")
    new SearchList(".filter__search input")
    new SearchList(".mobile-filter__search input")
    new SearchList(".brands-search-wrapper input", true)
  },

  initHomepageArticles: function () {
    if (this.settings.homepageArticle.length) {
      new Swiper(this.settings.homepageArticle.get(0), {
        slidesPerView: 1.5,
        lazy: true,
        navigation: {
          nextEl: '.homepage__articles .swiper-button-next',
          prevEl: '.homepage__articles .swiper-button-prev',
        },
        spaceBetween: 10,
        breakpoints: {
          576: {
            slidesPerView: 2.5
          },
          992: {
            slidesPerView: 5
          }
        }
      })
    }
  },

  initHomepageNewProductsSlider: function () {
    window.initHomepageNewProductsSlider = function() {
      if ($('.homepage__new-products').find('.swiper-container').length) {
        new Swiper($('.homepage__new-products').find('.swiper-container').get(0), {
          slidesPerView: 4,
          slidesPerGroup: 4,
          watchOverflow: true,
          spaceBetween: 10,
          pagination: {
            el: '.homepage__new-products .new-products-pagination',
            clickable: true
          },
          breakpoints: {
            576: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            992: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }
        })
      }
    }
  },

  initHomepagePromotionsSlider: function () {
    window.initHomepagePromotionsSlider = function() {
      setTimeout(() => {
        const homepagePromotionsSlider = $('.homepage__promotions')

        let initial_tab = homepagePromotionsSlider.find('.tab-content').find('.tab-pane.active')
        this.initHomepagePromotions(initial_tab)

        homepagePromotionsSlider.find('a[data-toggle="tab"]').on('shown.bs.tab', () => {
          let active_tab = $(this)

          homepagePromotionsSlider.find('.tab-content').children().each(function () {
            if ($(this).hasClass('active')) {
              active_tab = $(this)
            }
          });

          this.initHomepagePromotions(active_tab)
        })
      }, 300)
    }
  },

  initHomepagePromotions: function () {
    window.initHomepagePromotions = function(active_tab) {
      setTimeout(() => {
        if (document.querySelector('.homepage-promotions--swiper-container')) {
          let promotionsSwiper = new Swiper(active_tab.find('.homepage-promotions--swiper-container'), {
            slidesPerView: 2,
            spaceBetween: 30,
            watchOverflow: true,
            navigation: {
              nextEl: '.homepage__promotions .swiper-button-next',
              prevEl: '.homepage__promotions .swiper-button-prev',
            },
            pagination: {
              el: '.homepage__promotions .swiper-pagination',
              type: 'custom',
              renderCustom: function (swiper, current, total) {
                return `<div>0${current} </div> <div>/0${total} </div>`;
              }
            },
            breakpoints: {
              576: {
                slidesPerView: 2,
                spaceBetween: 5
              }
            }
          })

          promotionsSwiper.on('afterInit', function () {
            new HeightEqualizer()
          })
        }
      })
    }
  },

  initYoutubeVideos: function () {
    setTimeout(function () {
      var youtube = document.querySelectorAll('.youtube')

      for (var i = 0; i < youtube.length; i++) {
        var source = 'https://img.youtube.com/vi/' + youtube[i].dataset.embed + '/hqdefault.jpg';
        var image = new Image();

        image.src = source;
        image.addEventListener('load', function () {
          youtube[i].style.backgroundImage = 'url("' + source + '")';
        } ( i ) )

        $(youtube[i]).on( 'click', function() {
          var ifrm = document.createElement('iframe')
          ifrm.setAttribute('src', 'https://www.youtube.com/embed/'+ this.dataset.embed +'?rel=0&showinfo=0&autoplay=1')
          ifrm.setAttribute('allow', 'autoplay')
          $(this).append(ifrm)
          image.classList.add('hidden')
        })
      }
    }, 1000)
  },

  initBrandsSlider: function () {
    const homepageBrands = document.querySelector('.homepage__brands')

    if (homepageBrands) {
      window.homepageBrandsSwiper = new Swiper(document.querySelector('.homepage__brands .swiper-container'), {
        slidesPerView: 1,
        lazy: true,
        spaceBetween: 4,
        breakpoints: {
          360: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 4
          },
          992: {
            slidesPerView: 5
          },
          1400: {
            slidesPerView: 7
          }
        },
        navigation: {
          nextEl: '.homepage__brands .swiper-container .swiper-button-next',
          prevEl: '.homepage__brands .swiper-container .swiper-button-prev'
        }
      })
    }
  },

  /////////// END HOMEPAGE

  /////////// PRODUCT PAGE

  initRelatedProductsSlider: function () {
    window.initializeRelatedProductsSlider = function () {
      if ($(".related-products").length) {
        let el = $(".related-products .swiper-container")

        setTimeout(() => {
          let relatedSlider = new Swiper(el.get(0), {
            slidesPerView: 1,
            spaceBetween: 0,
            watchOverflow: true,
            navigation: {
              nextEl: '.related-products .swiper-button-next',
              prevEl: '.related-products .swiper-button-prev'
            },
            breakpoints: {
              576: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              }
            }
          })
        })
      }
    }

    window.initializeRelatedProductsSlider()
  },

  initProductThumbNavigation: function () {
    window. initProductThumbNavigation = function () {
      const productPage = document.querySelector(".product-page")

      if (productPage) {
        setTimeout(() => {
          const thumbs = document.querySelectorAll(".gallery-thumbs.swiper-container .swiper-slide")

          if (thumbs.length > 4) {
            const swiperNav = productPage.querySelectorAll(".gallery-thumbs .swiper-nav")

            swiperNav.forEach(function(swiperArrow) {
              swiperArrow.style.display = "block"
            })
          }
        }, 1000)
      }
    }

    window.initProductThumbNavigation()
  },

  initRecentProductsSlider: function () {
    window.initializeRecentProductsSlider = function () {
      if ($(".recent-products").length) {
        let el = $(".recent-products .swiper-container")

        setTimeout(() => {
          let recentSlider = new Swiper(el.get(0), {
            slidesPerView: 1,
            spaceBetween: 0,
            watchOverflow: true,
            navigation: {
              nextEl: '.recent-products .swiper-button-next',
              prevEl: '.recent-products .swiper-button-prev'
            },
            breakpoints: {
              576: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              }
            }
          })
        })
      }
    }

    window.initializeRecentProductsSlider()
  },

  initArticlesPageSlider: function () {
    if (this.settings.articleSlider.length) {
      new Swiper(this.settings.articleSlider.get(0), {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 20,
        watchOverflow: true,

        pagination: {
          el: '.article-page__slider .swiper-pagination',
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20
          }
        }
      })
    }
  },

  brandLandingSlider: function () {
    window.initBrandLandingSlider = function(){
      const selector = ".brands-page__split-section"
      const brandPage = document.querySelector(selector)

      if (brandPage) {
        new Swiper(brandPage.querySelector(".swiper-container"), {
          slidesPerView: 1,
          slidesPerGroup: 2,
          draggable: true,
          observer: true,
          watchOverflow: true,
          navigation: {
            nextEl: `${selector} .swiper-button-next`,
            prevEl: `${selector} .swiper-button-prev`,
          },
          breakpoints: {
            576: {
              slidesPerView: 2,
              slidesPerGroup: 4,
            },
            992: {
              slidesPerView: 2,
              slidesPerGroup: 4,
            },
          }
        })
      }
    }
  },

  initBrandDiscoverTabSlider: function () {
    window.initBrandDiscoverTabSlider = function() {
      setTimeout(() => {
        const brandDiscoverSlider = $('.brands-page__discover-section')

        let initial_tab = brandDiscoverSlider.find('.tab-content').find('.tab-pane.active')
        this.initBrandDiscoverSlider(initial_tab)

        brandDiscoverSlider.find('a[data-toggle="tab"]').on('shown.bs.tab', () => {
          let active_tab = $(this)

          brandDiscoverSlider.find('.tab-content').children().each(function () {
            if ($(this).hasClass('active')) {
              active_tab = $(this)
            }
          });

          this.initBrandDiscoverSlider(active_tab)
        })
      }, 400)
    }
  },

  brandDiscoverSlider: function () {
    window.initBrandDiscoverSlider = function(active_tab){
      const selector = '.brands-page__discover-section'
      const brandPage = document.querySelector(selector)

      if (brandPage) {
        new Swiper(active_tab.find('.brands-page--swiper-container'), {
          slidesPerView: 2,
          slidesPerGroup: 2,
          draggable: true,
          observer: true,
          watchOverflow: true,
          navigation: {
            nextEl: `${selector} .swiper-button-next`,
            prevEl: `${selector} .swiper-button-prev`
          },
          breakpoints: {
            576: {
              slidesPerView: 2,
              slidesPerGroup: 4
            },
            992: {
              slidesPerView: 2,
              slidesPerGroup: 4
            }
          }
        })
      }
    }
  },

  initHeightEqualizer: function () {
    window.initHeightEqualizer = function() {
      new HeightEqualizer()

      const tabs = document.querySelectorAll("a[data-toggle='tab']")

      for (let tab of tabs) {
        tab.addEventListener("click", function () {
          setTimeout(() => {
            new HeightEqualizer(`${tab.getAttribute("href")} .equal-height`)
          }, 300)

        }, { once: true })
      }
    }
  },

  initStaticPagesMenu: function () {
    if ($(".sidebar-nav").length) {
      var urlpath = window.location.pathname

      $(".sidebar-nav li").each(function () {
        if (urlpath.indexOf($(this).data("menulink")) != -1) {
          $(this).addClass("active")
        }
      })
    }
  },

  initMainMenu: () => {
    $(".main-menu--desktop .main-menu__l1--item").each(function() {
      let el = $(this)

      hoverIntent(el.get(0), function() {
         el.addClass('main-menu__l1--open')
       }, function() {
         el.removeClass('main-menu__l1--open')
       }).options({
         interval: 100
        }
      )
    })

    $(".main-menu--desktop .main-menu__l2--item").hover(
      function () {
        $(this).find('.main-menu__l3--wrapper').addClass('main-menu__l3--open')
      },
      function () {
        $(this).find('.main-menu__l3--wrapper').removeClass('main-menu__l3--open')
      }
    )

    $('.footer__menu--wrapper-title').on('click', function (e) {
      e.preventDefault()
      $(this).parent().find('.footer__menu--list').toggleClass('open')
    })
  },

  catalogFilters: function () {
    window.catalogFilters = function () {
      const currentDocument = $(document)

      $('.mobile-filter--list .dropdown').on('show.bs.dropdown', function() {
        $('.mobile-filter--list-open').removeClass('mobile-filter--list-open')
      })

      $('.mobile-filter--filter-title').on('click', function () {
        $(this).parent().toggleClass('mobile-filter--list-open')
      })

      $('.mobile-filter__filter-title').on('click', function () {
        $(this).closest('.mobile-filter__filters--item').toggleClass('mobile-filter__filters--item-open')
        $('.mobile-filter__close').addClass('d-flex')
      })

      currentDocument.on('click', '.mobile-filter__close', function () {
        const menu = $('.mobile-filter')
        const level = menu.find('.mobile-filter__filters--item-open')

        level.removeClass('mobile-filter__filters--item-open')

        if (level.length <= 1 ) menu.find('.mobile-filter__close').removeClass('d-flex')
      })
    }

    window.catalogFilters()
  },

  faqToggler: function () {
    $('.faq-item').on('click', function () {
      $(this).toggleClass('active')
    })
  },

  // Responsive Menu
  responsiveMenu: function () {
    let $this = this
    const currentDocument = $(document)

    currentDocument.on('click', '.mobile-menu-trigger', function (e) {
      e.preventDefault()
      $('.main-menu').removeClass('main-menu--desktop').addClass('mobile-menu d-block')
      window.zopimHide()
      $this.freezeBackground()
    })

    currentDocument.on('click', '.main-menu .close-mobile', function (e) {
      e.preventDefault()
      $('.main-menu').removeClass('mobile-menu d-block').addClass('main-menu--desktop')
      window.zopimShow()
      $this.unfreezeBackground()
    })

    currentDocument.on('click', '.next-level .icon-right-arrow-bold', function (e) {
      $(this).parent().addClass('active-menu-level')
      $(this).parent().parent().addClass('main-menu__l1--active')
      $('.back-level').addClass('d-block')
    })

    currentDocument.on('click', '.back-level', function (e) {
      const menu = $('.mobile-menu')
      const level = menu.find('.active-menu-level')

      level.last().removeClass('active-menu-level')
      level.last().parent().removeClass('main-menu__l1--active')

      if (level.length <= 1 ) menu.find('.back-level').removeClass('d-block')
    })
  },


  // Change 3rd level of main menu
  changeMenuBannersOnHover: function() {
    let categoryTitles_level2 = document.querySelectorAll(".main-menu__l2--item")

    if (categoryTitles_level2) {
      for (let i = 0; i < categoryTitles_level2.length; i++) {

        categoryTitles_level2[i].addEventListener("mouseenter", function(e) {
          let banner = this.querySelector(".main-menu__banner img")

          if (this.hasAttribute("data-banner")) {
            banner.setAttribute("src", this.getAttribute("data-banner"))
            banner.style.display = "block"
          } else {
            banner.style.display = "none"
          }
        })
      }
    }

    let categoryTitles_level3 = document.querySelectorAll(".main-menu__l3--item")
    if (categoryTitles_level3) {
      for (let i = 0; i < categoryTitles_level3.length; i++) {

        categoryTitles_level3[i].addEventListener("mouseenter", function(e) {
          let banner = this.parentElement.parentElement.querySelector(".main-menu__banner img")

          if (this.hasAttribute("data-banner")) {
            banner.setAttribute("src", this.getAttribute("data-banner"))
            banner.style.display = "block"
          } else {
            banner.style.display = "none"
          }
        })
      }
    }
  },

  // Search Header
  searchInit: function() {
    const search = $('.header__search')
    search.find('.magnifying-glass').on('click', function() {
      search.toggleClass('open')
    })
  },


  initStickyHeader: function() {
    window.addEventListener('load', function() {
      let body = document.body,
        html = document.documentElement,
        height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

      if (height > 1800) {
        let stickyOffset = $('.header').offset().top

        $(window).scroll(function() {
          let logo_img = document.querySelector('.header__logo img'),
            headerContent = $('.header__logo-search-icons-container'),
            header = $('.header'),
            scroll = $(window).scrollTop(),
            headerInfo = $('.header__info')

          if (scroll > stickyOffset) {
            if (header.hasClass('homepage')) {
              header.removeClass('position-relative')
              header.addClass('position-fixed')
            } else {
              header.addClass('position-fixed')
            }

            logo_img.style.width = '150px'
            logo_img.style.height = 'auto'

            headerContent.addClass('mb-3')
            headerInfo.addClass('d-none')
          } else {
            if (header.hasClass('homepage')) {
              header.removeClass('position-fixed')
              header.addClass('position-relative')
            } else {
              header.removeClass('position-fixed')
            }

            logo_img.style.width = ''
            logo_img.style.height = ''

            headerContent.removeClass('mb-3')
            headerInfo.removeClass('d-none')
          }
        })
      }
    })
  },

  catalogBannerChecker: function() {
    let catalogBanner = document.querySelector(".catalog-banner")

    if (catalogBanner) {
      let header = document.querySelector(".header__fix-scroll")
      header.classList.add("fixed-header")
    }
  },

  stickyButtons: function() {
    window.addEventListener('load', function() {
      const productPage = document.querySelector('.product-page')
      const buttons =  document.querySelector('.sticky-btn__container')
      const payMethods = document.querySelector('.footer__payment-methods')
      const quickBuyButtons = document.querySelector('.mobile-buy--action-buttons')


      if (window.innerWidth < 576) {
        if (productPage) {
          const height = quickBuyButtons.offsetHeight

          payMethods.style.paddingBottom =  height + 'px' ;

          if (buttons) buttons.style.display = 'none';
        }
        else {
          const height = buttons.offsetHeight
          payMethods.style.paddingBottom = height + 'px' ;
        }
      }
    })
  },

  // Animate
  animate: function() {
    var $window = $(window)
    var win_height_padded = $window.height() * 1.1

    $window.on('scroll', revealOnScroll)

    function revealOnScroll() {
      var scrolled = $window.scrollTop()

      $(".revealOnScroll:not(.animated)").each(function () {
        var $this     = $(this),
            offsetTop = $this.offset().top

        if (scrolled + win_height_padded > (offsetTop + 200)) {
          if ($this.data('timeout')) {
            window.setTimeout(function(){
              $this.addClass('animated ' + $this.data('animation'))
              $this.css("opacity", "1")
            }, parseInt($this.data('timeout'),10))
          } else {
            $this.addClass('animated ' + $this.data('animation'))
            $this.css("opacity", "1")
          }
        }
      })
    }
  },

  initFiltersToggle: function() {
    window.initFiltersToggle = function () {
      $('.filter__title').on('click', function() {
        $(this).parents('.filter').toggleClass('close-filter')
      })
    }

    window.initFiltersToggle()
  },

  newsletterPopup: function() {
    // ATTENTION: Change this every time the pop-up changes
    let cookieName = 'newsletter_default_popup'

    if (Cookies.get(cookieName) !== 'true') {
      setTimeout( function() {
        $('#newsletterModal').modal('show')
        Cookies.set(cookieName, 'true', { expires: 1440 }) // NOTE: Cookie Expires in 5 minutes
      }, 2500)
    }
  },

  openSearch: function () {
    window.openSearchMobile = function () {
      if (window.innerWidth < 1200) {
        const _openSearch = $(".header__search .icon-search")
        const _searchPanel = $(".search-panel")

        _openSearch.on("click touchstart", function () {
          _searchPanel.modal("show")
          $("#searchModal").on("shown.bs.modal", function () {
            $(this).find("input[type=text]").focus();
          });
        })
      }
    }

    window.openSearchMobile()
  },

  initZopimManager: function (){
    window.zopimHide = function (){
      const zopim = document.querySelectorAll(".zopim")
      if (window.innerWidth < 1200){
        zopim[1].style.zIndex = -1
      }
    }

    window.zopimShow = function (){
      const zopim = document.querySelectorAll(".zopim")
      if (window.innerWidth < 1200){
        zopim[1].style.zIndex = 16000001
      }
    }
  },

  initCheckoutFocusManager: function () {
    const checkoutInputs = document.querySelectorAll('.checkout-2 .form-group input')
    const header = document.querySelector('.header')
    const ua = navigator.userAgent

    if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      checkoutInputs.forEach(function(input) {
        input.addEventListener('focus', (event) => {
          header.classList.add('input-focus')
        })

        input.addEventListener('focusout', (event) => {
          header.classList.remove('input-focus')
        })
     })
    }
  },

  initMenuTabs: function() {
    window.initMenuTabs = function () {
      $(".main-menu--container ul.nav-tabs a").click(function (e) {
        e.preventDefault();
          $(this).tab("show")
      })
    }

    window.initMenuTabs()
  }

}

GY.init()
